import React from "react";
import styles from "./footer.module.scss";
import Logo from "../../../Assets/Images/logo.svg";
import instaIcon from "../../../Assets/Images/instaIcon.svg";
import twitterIcon from "../../../Assets/Images/twitterIcon.svg";
import discordIcon from "../../../Assets/Images/discordIcon.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img
          className={styles.imageWrapper}
          src={Logo}
          alt="logo"
          data-aos="fade-up"
        />
        <div className={styles.linksWrapper}>
          <div className={styles.links}>
            <Link to="/" className={styles.text}>
              About
            </Link>
            <Link to="/" className={styles.text}>
              Roadmap
            </Link>
            <Link to="/" className={styles.text}>
              Team
            </Link>
            <Link to="/wallet" className={styles.text}>
              Wallet
            </Link>
          </div>
          <div className={styles.links}>
            <img className={styles.icon} src={instaIcon} />
            <img className={styles.icon} src={twitterIcon} />
            <img className={styles.icon} src={discordIcon} />
          </div>
        </div>
        <div className={styles.copyRight}>
          © 2022 Dreamerz, All rights reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
