import React from "react";
import styles from "./card.module.scss";
import cardImage from "../../../Assets/Images/cardImage.svg";
import { useNavigate } from "react-router-dom";

const Card = ({ data, index }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/wallet/${index + 1}`, { state: data });
  };
  return (
    <>
      <div className={styles.card} onClick={handleClick} data-aos="flip-left">
        <div className={styles.imgWrapper}>
          <img src={data?.image} alt="Avatar" width={"100%"} />
        </div>

        <div className={styles.container}>
          <div className={styles.textWrapper}>
            <div className={styles.title} style={{ fontSize: "2rem" }}>
              Dreamerz
            </div>
            <div className={styles.desc} style={{ fontSize: "1.3rem" }}></div>
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.title} style={{ fontSize: "1.3rem" }}>
              {`#${data?.id}`}
            </div>
            <div className={styles.desc} style={{ fontSize: "2rem" }}></div>
          </div>
          {/* <div
            className={styles.textWrapper}
            style={{ justifyContent: "flex-end" }}
          >
            <div className={styles.title} style={{ fontSize: "1.3rem" }}></div>
            <div
              className={styles.desc}
              style={{ fontSize: "1.4rem", marginLeft: "1rem" }}
            ></div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Card;
