import React, { useEffect, useState } from "react";
import { tokenURIData } from "../../Services/mint.helper";
import { useActiveWeb3React } from "../../Services/WalletConnection/useWalletConnect";

const useWallet = () => {
  const { account, chainId } = useActiveWeb3React();
  const [tokenList, setTokenList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getTokenData();
  }, [account, chainId]);
  const getTokenData = async () => {
    try {
      setLoading(true);
      const res = await tokenURIData(account);
      setTokenList(res);
      setLoading(false);
    } catch (error) {
      setTokenList([]);
      setLoading(false);
      console.log("error", error);
    }
  };

  return { tokenList, loading, account };
};

export default useWallet;
