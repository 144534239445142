import React from "react";
import styles from "./card.module.scss";
import Skeleton from "react-loading-skeleton";

const CardLoading = () => {
  return (
    <>
      <div className={styles.card}>
        <div
          className={styles.imgWrapper}
          style={{ borderBottom: "1px solid #ae8aca" }}
        >
          <Skeleton
            baseColor="#370174"
            highlightColor="#ae8aca"
            height={"30rem"}
            borderRadius ={"1.5rem"}
          />
        </div>

        <div className={styles.container}>
          <div className={styles.textWrapper}>
            <div className={styles.title}>
              <Skeleton
                baseColor="#370174"
                highlightColor="#ae8aca"
                height={20}
                width={100}
              />
            </div>
            <div className={styles.desc}>
              <Skeleton
                baseColor="#370174"
                highlightColor="#ae8aca"
                height={20}
                width={100}
              />
            </div>
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.title}>
              <Skeleton
                baseColor="#370174"
                highlightColor="#ae8aca"
                height={20}
                width={100}
              />
            </div>
            <div className={styles.desc}>
              <Skeleton
                baseColor="#370174"
                highlightColor="#ae8aca"
                height={20}
                width={100}
              />
            </div>
          </div>
          <div
            className={styles.textWrapper}
            style={{ justifyContent: "flex-end" }}
          >
            <div className={styles.title}>
              <Skeleton
                baseColor="#370174"
                highlightColor="#ae8aca"
                height={20}
                width={100}
              />
            </div>
            <div className={styles.desc}>
              <Skeleton
                baseColor="#370174"
                highlightColor="#ae8aca"
                height={20}
                width={100}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardLoading;
