import React from "react";
import styles from "./wallet.module.scss";
import walletBanner from "../../Assets/Images/bannerWallet.png";
import animeLogo from "../../Assets/Images/anime2.svg";
import instaIcon from "../../Assets/Images/instaIcon.svg";
import twitterIcon from "../../Assets/Images/twitterIcon.svg";
import discordIcon from "../../Assets/Images/discordIcon.svg";
import noData from "../../Assets/Images/noData.svg";
import Card from "./card.js";
import CardLoading from "./card.js/loading";
import useWallet from "./useWallet";
import useMint from "../Mint/useMint";

const Wallet = () => {
  const toggle = () => {};
  const { tokenList, loading, account } = useWallet();
  const { ownerTokens, maximumSupply } = useMint({ toggle });
  return (
    <div className={styles.wrapper}>
      <div className={styles.banner} data-aos="zoom-in-up">
        <img src={walletBanner} alt="walletBanner" />
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.left} data-aos="fade-right">
          <img src={animeLogo} alt={"animeLogo"} />
        </div>
        <div className={styles.right}>
          <div className={styles.title}>Dreamerz</div>
          <div className={styles.description}>
            The Bored Ape Yacht Club is a collection of 10,000 unique Bored Ape
            NFTs— unique digital collectibles living on the Ethereum blockchain.
            Your Bored Ape doubles as your Yacht Club membership card, and
            grants access to members-only benefits, the first of which is access
            to THE BATHROOM, a collaborative graffiti board. Future areas and
            perks can be unlocked by the
          </div>
          <div className={styles.links}>
            <img className={styles.icon} src={instaIcon} />
            <img className={styles.icon} src={twitterIcon} />
            <img className={styles.icon} src={discordIcon} />
          </div>
          <div className={styles.cardWrapper}>
            <div className={styles.card}>
              <div className={styles.label}>Total Volume</div>
              <div className={styles.text}>
                {maximumSupply ? maximumSupply : "0"}
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.label}>Balance</div>
              <div className={styles.text}>
                {ownerTokens ? ownerTokens?.length : "0"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardWrapper2}>
        {loading ? (
          <>
            <CardLoading />
            <CardLoading />
            <CardLoading />
            <CardLoading />
          </>
        ) : (
          <>
            {tokenList?.length ? (
              tokenList.map((token, index) => (
                <Card data={token} index={index} key={index} />
              ))
            ) : (
              <div className={styles.noData}>
                <img src={noData} alt="noData" />
                <div className={styles.noDataText}>
                  {account ? "No Data Available !" : "First Connect to Wallet"}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Wallet;
