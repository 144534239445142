import React from "react";
import { useNavigate } from "react-router-dom";
import notFound from "../../Assets/Images/notFound.png";
import styles from "./notFound.module.scss";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <img src={notFound} />
      <button className={styles.button} onClick={() => navigate("/")}>
        Back to Main
      </button>
    </div>
  );
};

export default NotFound;
