import React from "react";
import ModalLayout from "../GenericModal/ModalLayout";
import styles from "./wrongNetwork.module.scss";
import Logo from "../../../Assets/Images/logo.svg";
import instaIcon from "../../../Assets/Images/instaIcon.svg";
import twitterIcon from "../../../Assets/Images/twitterIcon.svg";
import discordIcon from "../../../Assets/Images/discordIcon.svg";

const WrongNetwork = ({ isOpen, toggle }) => {
  return (
    <>
      <ModalLayout isOpen={isOpen} toggle={toggle} cross={false}>
        <div className={styles.wrapper}>
          <img
            className={styles.imageWrapper}
            src={Logo}
            alt="logo"
            data-aos="zoom-in"
          />
          <div className={styles.mainheading}>Wrong Network</div>
          <div className={styles.text}>
            Please change network you are not on an appropriate network.
          </div>
          <div className={styles.links}>
            <img className={styles.icon} src={instaIcon} />
            <img className={styles.icon} src={twitterIcon} />
            <img className={styles.icon} src={discordIcon} />
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default WrongNetwork;
