import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import {
  checkMember,
  checkOfferMint,
  maxSupply,
  mintFee,
  mintNFT,
  tokensOfOwner,
  totalSupply,
} from "../../Services/mint.helper";
import { useActiveWeb3React } from "../../Services/WalletConnection/useWalletConnect";

const useMint = ({ toggle }) => {
  const alert = useAlert();
  const [amount, setAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [mintedFee, setMintedFee] = useState(null);
  const [maximumSupply, setMaximumSupply] = useState(0);
  const [maximumNft, setMaximumNft] = useState(1);
  const [nftSupply, setNftSupply] = useState(0);
  const [ownerTokens, setOwnerTokens] = useState([]);
  const { account, chainId } = useActiveWeb3React();
  useEffect(() => {
    account && getMintFee();
    account && getMaximumSupply();
    account && getNFTSupply();
    account && getCheckMember();
    getOwnerToken();
  }, [account, chainId]);

  let incNum = () => {
    if (amount < maximumNft) {
      setAmount(Number(amount) + 1);
    }
  };
  let decNum = () => {
    if (amount > 1) {
      setAmount(Number(amount) - 1);
    }
  };
  const inputHandler = (e) => {
    setAmount(e.target.value);
  };
  let getMaximumSupply = async () => {
    try {
      const res = await maxSupply();
      setMaximumSupply(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  let getCheckMember = async () => {
    try {
      const res = await checkMember(account);
      setMaximumNft(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  let getNFTSupply = async () => {
    try {
      const res = await totalSupply();
      setNftSupply(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  let getOwnerToken = async () => {
    try {
      const res = await tokensOfOwner(account);
      setOwnerTokens(res);
      console.log("res mint ", res);
    } catch (error) {
      setOwnerTokens([]);
      console.log("error", error);
    }
  };
  let getMintFee = async () => {
    try {
      const res = await mintFee();
      setMintedFee(res);
      console.log("res mint ", res);
    } catch (error) {
      console.log("error", error);
    }
  };
  const submitHandler = async () => {
    try {
      if (!account) {
        toggle();
      } else {
        try {
          setLoading(true);
          const res = await mintNFT(account, amount, maximumNft);
          console.log("res", res);
          getMaximumSupply();
          getNFTSupply();
          getOwnerToken();
          setLoading(false);
          alert.show("Minted Successfully", { type: "success" });
        } catch (error) {
          setLoading(false);
          alert.show("Something Went Wrong", { type: "error" });
        }
      }
    } catch (error) {}
  };
  return {
    inputHandler,
    amount,
    incNum,
    decNum,
    submitHandler,
    mintedFee,
    loading,
    nftSupply,
    ownerTokens,
    maximumSupply,
    maximumNft,
  };
};

export default useMint;
