import Web3 from "web3";

export const ConvertToWEI = (data) => {
  const value = Web3.utils.toWei(Number(data).toFixed(8).toString(), "ether");
  return value;
};
export const ConvertFromWEI = (data) => {
  const value = Web3.utils.fromWei(data, "ether");
  const value2 = value.toString();
  return value2;
};
