import React from "react";
import styles from "./button.module.scss";

const GenericButton = ({ onClick, children, styleType = 1 }) => {
  return (
    <button
      className={`${styleType == 1 ? `${styles.button}` : `${styles.button2}`}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default GenericButton;
