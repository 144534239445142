import React, { useEffect } from "react";
import styles from "./nft.module.scss";
import backIcon from "../../Assets/Images/back.svg";
import detailNFT from "../../Assets/Images/detailNFT.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useActiveWeb3React } from "../../Services/WalletConnection/useWalletConnect";
import { MINT_CONTRACT } from "../../Utils/contract";
import { ContractUrl } from "../../Utils/AppConstant";

const NFTDetail = () => {
  const location = useLocation();
  const { account, chainId } = useActiveWeb3React();
  const data = location?.state;
  const navigate = useNavigate();
  useEffect(() => {
    if (!location?.state) {
      navigate(`/not-found`);
    }
  }, [location]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.back} onClick={() => navigate("/wallet")}>
        <img className={styles.backIcon} src={backIcon} />
        <div className={styles.text}>Back</div>
      </div>
      <div className={styles.detailWrapper}>
        <div className={styles.left} data-aos="zoom-in-up">
          <img src={data?.image} alt={detailNFT} />
        </div>
        <div className={styles.right}>
          <div className={styles.title}>Dreamerz</div>
          <div className={styles.num}>#{data?.id}</div>
          <div className={styles.owner}>
            OWNED BY
            <span>
              {` ${account?.substring(0, 6)} ...
              ${account?.substring(account?.length - 4)}`}
            </span>
          </div>
          <div className={styles.date}>
            Contract Address
            <span onClick={() => window.open(`${ContractUrl}${MINT_CONTRACT}`)}>
              {` ${MINT_CONTRACT?.substring(0, 6)} ...
              ${MINT_CONTRACT?.substring(MINT_CONTRACT.length - 4)}`}
            </span>
          </div>
          <div className={styles.owner}>
            Blockchain
            <span>{" Ethereum"}</span>
          </div>
          <div className={styles.cardWrapper}>
            {data?.attributes.map((item) => (
              <div className={styles.card}>
                <div className={styles.label}>{item?.trait_type}</div>
                <div className={styles.text}>{item?.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTDetail;
