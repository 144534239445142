import { ConvertFromWEI } from "./common.helper";
import { GetsMintContract } from "./Contract/contract.helper";
import axios from "axios";

export const checkMember = async (account) => {
  try {
    let memberNftLimit = 1;
    const customContract = await GetsMintContract();
    const isGold = await customContract.methods.goldMember(account).call();
    const isWhite = await customContract.methods.whiteMember(account).call();
    if (isGold) {
      memberNftLimit = 5;
    } else if (isWhite) {
      memberNftLimit = 2;
    } else {
      memberNftLimit = 1;
    }
    const checkMemberType = await checkOfferMint();
    if (checkMemberType) {
      return checkMemberType;
    } else {
      return memberNftLimit;
    }
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const mintFee = async () => {
  try {
    const customContract = await GetsMintContract();
    const data = await customContract.methods.mintFee().call();
    const res = ConvertFromWEI(data?.toString());
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const maxSupply = async () => {
  try {
    const customContract = await GetsMintContract();
    const res = await customContract.methods.maxSupply().call();
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const totalSupply = async () => {
  try {
    const customContract = await GetsMintContract();
    const res = await customContract.methods.totalSupply().call();
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const tokensOfOwner = async (account) => {
  try {
    const customContract = await GetsMintContract();
    const res = await customContract.methods.tokensOfOwner(account).call();
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const mintNFT = async (account, amount, maximumNft) => {
  try {
    const customContract = await GetsMintContract();
    const mintFee = await customContract.methods.mintFee().call();
    const mintFees = mintFee * amount;
    const checkMem = await checkMember(account);
    const discountedPrice = mintFees * 0.75;
    debugger;
    const res = await customContract.methods
      .mint(account, amount)
      .send({
        value: checkMem === 5 ? discountedPrice : mintFees,
        from: account,
      });
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const tokenURIData = async (account) => {
  try {
    const customContract = await GetsMintContract();
    const tokenList = await tokensOfOwner(account);
    // const tList = tokenList?.slice(0, 5);
    // debugger;
    const tokenURI = await Promise.all(
      tokenList?.map(async (id, index) => {
        const uri = await customContract.methods.tokenURI(id).call();
        const tokenUri = uri.replace("ipfs://", "https://ipfs.io/ipfs/");
        const uriData = await axios.get(tokenUri);
        const imageUrl = uriData?.data?.image.replace(
          "ipfs://",
          "https://ipfs.io/ipfs/"
        );
        const res = { ...uriData?.data, image: imageUrl, id };
        return res;
      })
    );
    return tokenURI;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};

export const checkOfferMint = async () => {
  const customContract = await GetsMintContract();
  const dataMintMilli = await customContract.methods.mintStart().call();
  const currentDate = new Date();
  const dataMintNum = Number(dataMintMilli);
  let dataMint = new Date(0); // The 0 there is the key, which sets the date to the epoch
  dataMint.setUTCSeconds(dataMintNum);
  let Difference_In_Time = currentDate - dataMint;
  if (Difference_In_Time >= 172800000) {
    return 1;
  } else if (Difference_In_Time <= 0) {
    return 1;
  }
  return 0;
};
