import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import Web3ReactManager from "./Services/WalletConnection/Web3ReactManager";
import Web3 from "web3";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./Components/Common/Alert/AlertTemplate";
import 'react-loading-skeleton/dist/skeleton.css'

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "10px",
  transition: transitions.SCALE,
};
const Web3ProviderNetwork = createWeb3ReactRoot("Network");
function getLibrary(provider) {
  return new Web3(provider);
}

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Web3ReactManager>
            <App />
          </Web3ReactManager>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
