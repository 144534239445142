import React, { useState } from "react";
import styles from "./mint.module.scss";
import instaIcon from "../../Assets/Images/instaIcon.svg";
import twitterIcon from "../../Assets/Images/twitterIcon.svg";
import discordIcon from "../../Assets/Images/discordIcon.svg";
import minus from "../../Assets/Images/minus.svg";
import plus from "../../Assets/Images/plus.svg";
import useMint from "./useMint";
import { useActiveWeb3React } from "../../Services/WalletConnection/useWalletConnect";
import Loader from "../Common/Loader";

const Mint = ({ isOpen, toggle }) => {
  const {
    inputHandler,
    amount,
    incNum,
    decNum,
    submitHandler,
    mintedFee,
    loading,
    nftSupply,
    ownerTokens,
    maximumSupply,
    maximumNft,
  } = useMint({
    toggle,
  });
  const { account, chainId } = useActiveWeb3React();
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title} data-aos="zoom-in-down">
          Mint Now
        </div>
        <div className={styles.container} data-aos="zoom-in">
          <div className={styles.connectTitle}>
            {account ? (
              <>
                <div className={styles.label}>Connected Wallet: </div>
                <div className={styles.text}>{account}</div>
              </>
            ) : (
              <span className={styles.notConnectedLabel}>
                Wallet is not connected yet !
              </span>
            )}
          </div>
          <div className={styles.nftWrapper}>
            <div className={styles.nftTitle}>
              {maximumSupply && nftSupply
                ? `${nftSupply}/${maximumSupply} NFT's`
                : "0.00/0.00 NFT"}
            </div>
            <div className={styles.nftTitle}>
              Owner's Balance:{" "}
              {`${
                ownerTokens?.length ? `${ownerTokens.length}  NFT's` : `0 NFT`
              }`}
            </div>
            <div className={styles.nftTitle}>
              Minting Fee: {mintedFee ? `${mintedFee} ETH` : "0.00"}
            </div>
            <div className={styles.nftTitle}>{`Max ${maximumNft} NFTs`}</div>
          </div>
          {maximumNft > 1 && (
            <div className={styles.inputWrapper}>
              <div className={styles.buttonInput} onClick={decNum}>
                <img src={minus} alt={"minus"} />
              </div>
              <input
                placeholder="0"
                onChange={inputHandler}
                value={amount}
                type="number"
                min="1"
                max={maximumNft}
              />
              <div className={styles.buttonInput} onClick={incNum}>
                <img src={plus} alt={"plus"} />
              </div>
            </div>
          )}
          <button
            className={styles.button}
            onClick={submitHandler}
            disabled={loading}
          >
            {loading ? (
              <Loader />
            ) : (
              <span> {account ? "Mint Now" : "Connect Wallet"}</span>
            )}
          </button>
        </div>
        <div className={styles.linksWrapper} data-aos="fade-down">
          <img className={styles.icon} src={instaIcon} />
          <img className={styles.icon} src={twitterIcon} />
          <img className={styles.icon} src={discordIcon} />
        </div>
      </div>
    </>
  );
};

export default Mint;
