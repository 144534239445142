import React, { useState } from "react";
import Footer from "../Components/Common/Footer";
import Mint from "../Components/Mint";
import Navigation from "../Components/Common/Navigation";
import ConnectModal from "../Components/Common/WalletConnectModal/ConnectModal";


const MintPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <ConnectModal isOpen={isOpen} toggle={toggle} />
      <Navigation isOpen={isOpen} toggle={toggle} styleType={1} />
      <Mint isOpen={isOpen} toggle={toggle} />
      <Footer />
    </>
  );
};

export default MintPage;
