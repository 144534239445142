import React, { useState } from "react";
import Footer from "../Components/Common/Footer";
import Navigation from "../Components/Common/Navigation";
import ConnectModal from "../Components/Common/WalletConnectModal/ConnectModal";

import NotFound from "../Components/NotFound";

const NotFoundPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div style={{ background: "#370174" }}>
      <ConnectModal isOpen={isOpen} toggle={toggle} />
      <Navigation
        isOpen={isOpen}
        toggle={toggle}
        background={"#370174"}
        styleType={2}
      />
      <NotFound />

      <Footer />
    </div>
  );
};

export default NotFoundPage;
