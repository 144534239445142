import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MintPage from "./Pages/mint";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import WrongNetwork from "./Components/Common/WrongNetworkModal";
import { useActiveWeb3React } from "./Services/WalletConnection/useWalletConnect";
import { UnsupportedChainIdError } from "@web3-react/core";
import { NetworkTestChainId } from "./Utils/AppConstant";
import WalletPage from "./Pages/wallet";
import NFTDetailPage from "./Pages/nftDetail";
import ScrollToTop from "./Components/Common/scrollToTop";
import NotFoundPage from "./Pages/notFound";

function App() {
  const { account, chainId, error } = useActiveWeb3React();
  const isWrongNetwork = error && error instanceof UnsupportedChainIdError;
  useEffect(() => {
    AOS.init({
      duration: 1500,
      ease: "linear",
      once: true,
    });
    AOS.refresh();
  }, []);
  const WrongChainId = () => {
    if (chainId !== NetworkTestChainId) {
      return <WrongNetwork isOpen={true} />;
    }
  };
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<MintPage />} />
        <Route path="/wallet" element={<WalletPage />} />
        <Route path="/wallet/:id" element={<NFTDetailPage />} />
      </Routes>
      {(chainId || isWrongNetwork) && WrongChainId()}
    </BrowserRouter>
  );
}

export default App;
