import Web3 from "web3";
import MINT_ABI from "../../Utils/ABI/MINT_ABI.json";

import { MINT_CONTRACT } from "../../Utils/contract";

function GetProvider() {
  const web3 = new Web3();
  web3.setProvider(window.web3.currentProvider);
  return web3;
}
export const GetsMintContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(MINT_ABI, MINT_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
