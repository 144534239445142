import React, { useEffect, useState } from "react";
import styles from "./ConnectWallet.module.scss";
import MetaMask from "../../../Assets/Images/metaMask.svg";
import minimetaMask from "../../../Assets/Images/minimetaMask.svg";
import ConnectWallet from "../../../Assets/Images/ConnectWallet.svg";
import miniwalletConnect from "../../../Assets/Images/miniwalletConnect.svg";
import ModalLayout from "../GenericModal/ModalLayout";
import { useActiveWeb3React } from "../../../Services/WalletConnection/useWalletConnect";
import {
  injected,
  walletconnect,
} from "../../../Services/WalletConnection/WalletConnector";

const ConnectModal = ({ isOpen, toggle }) => {
  const [connect, setConnect] = useState(null);
  const { active, account, library, connector, activate, deactivate } =
    useActiveWeb3React();
  useEffect(() => {
    if (connector === injected) {
      setConnect(0);
    } else if (connector === walletconnect) {
      setConnect(1);
    }
  }, [connector]);
  const connectHandler = async (type) => {
    setConnect(type);
    if (connector && connector.walletConnectProvider) {
      connector.walletConnectProvider = undefined;
    }
    try {
      if (type === 0) {
        await activate(injected);
      }
      if (type === 1) {
        const walletResConnect = await activate(walletconnect);
      }
      // window.ethereum.request({ method: 'eth_requestAccounts' })
      // type===2&& await activate()
      toggle();
    } catch (ex) {
      toggle();
      console.log(ex);
    }
  };
  return (
    <>
      <ModalLayout isOpen={isOpen} toggle={toggle}>
        <div className={styles.mainheading}>Connect Your Wallet</div>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.btn1}
            onClick={() =>
              window.ethereum === undefined
                ? window
                    .open(
                      "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
                      "_blank"
                    )
                    .focus()
                : connectHandler(0)
            }
          >
            <div className={styles.Metaimg}>
              <img src={MetaMask} alt="metamask" />
            </div>
            <div className={styles.miniImage}>
              <img src={minimetaMask} alt="metamask" />
            </div>
            {window.ethereum === undefined ? (
              <div className={styles.MetaText}>Install MetaMask Extension</div>
            ) : (
              <div className={styles.MetaText}>Connect to Meta Mask</div>
            )}
            <div className={styles.outerCircle}>
              <div
                className={` ${
                  account && connect === 0
                    ? styles.innerCircleActive
                    : styles.innerCircleUnactive
                }`}
              ></div>
            </div>
          </button>
          <button className={styles.btn1} onClick={() => connectHandler(1)}>
            <div className={styles.Metaimg}>
              <img src={ConnectWallet} alt="walletConnect" />
            </div>
            <div className={styles.miniImage}>
              <img src={miniwalletConnect} alt="walletConnect" />
            </div>
            <div className={styles.MetaText}>Use Wallet Connect</div>
            <div className={styles.outerCircle}>
              <div
                className={` ${
                  account && connect === 1
                    ? styles.innerCircleActive
                    : styles.innerCircleUnactive
                }`}
              ></div>
            </div>
          </button>
        </div>
      </ModalLayout>
    </>
  );
};

export default ConnectModal;
