import React, { useEffect, useRef } from "react";
import styles from "./Navigation.module.scss";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../../Assets/Images/logo.svg";
import MenuIcon from "../../../Assets/Images/menuIcon.svg";
import { useState } from "react";
import MobileMenu from "../MobileMenu";
import { useActiveWeb3React } from "../../../Services/WalletConnection/useWalletConnect";
import GenericButton from "../GenericButton";

function Navigation({ toggle, background, styleType }) {
  const { account } = useActiveWeb3React();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuHandler = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <>
      <nav
        className={styles.wrapper}
        style={{
          justifyContent: isMobileMenuOpen ? "center" : "",
          background: background ? background : "transparent",
        }}
      >
        <div
          className={styles.spaceMobileView}
          style={{ paddingRight: !isMobileMenuOpen ? "24px" : "" }}
        ></div>
        <div className={styles.logo} data-aos="fade-down">
          <Link to="/">
            <img src={Logo} alt="Dreamerz Logo" />
          </Link>
        </div>
        {!isMobileMenuOpen && (
          <img
            src={MenuIcon}
            alt="Zah Logo"
            onClick={mobileMenuHandler}
            className={styles.menuIcon}
          />
        )}
        <div className={styles.links}>
          <div className={styles.linksWrapper}>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>About</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>
            <NavLink
              to="/roadmap"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>Roadmap</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>
            <NavLink
              to="/team"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>Team</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>
            <NavLink
              to="/wallet"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>Wallet</div>
                <div
                  className={styles.activeBar}
                />
              </div>
            </NavLink>
          </div>
          <GenericButton onClick={toggle} styleType={styleType}>
            {account ? (
              `${account.substring(0, 6)}...${account.substring(
                account.length - 4
              )}`
            ) : (
              <span>Connect Wallet</span>
            )}
          </GenericButton>
          {/* <button className={styles.button} onClick={toggle}>
            {account ? (
              `${account.substring(0, 6)}...${account.substring(
                account.length - 4
              )}`
            ) : (
              <span>Connect Wallet</span>
            )}
          </button> */}
        </div>
      </nav>
      {isMobileMenuOpen && (
        <MobileMenu
          mobileMenuHandler={mobileMenuHandler}
          isMobileMenuOpen={isMobileMenuOpen}
          toggle={toggle}
        />
      )}
    </>
  );
}

export default Navigation;
