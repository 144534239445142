import React from "react";
import styles from "./MobileMenu.module.scss";
import { motion } from "framer-motion";
import animations from "./MobileMenu.animate";
import closeIcon from "../../../Assets/Images/crossIcon.svg";
import { NavLink } from "react-router-dom";
import { useActiveWeb3React } from "../../../Services/WalletConnection/useWalletConnect";

const MobileMenu = ({ mobileMenuHandler, isMobileMenuOpen, toggle }) => {
  const { account, chainId } = useActiveWeb3React();
  return (
    <motion.div
      className={styles.wrapper}
      variants={animations.container}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className={styles.closeRow} onClick={mobileMenuHandler}>
        <img className={styles.closeIcon} src={closeIcon} alt={"closeIcon"} />
      </div>

      <div className={styles.linksContainer}>
        <div className={styles.links}>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : styles.inactive
            }
            to="/about"
          >
            About
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : styles.inactive
            }
            to="/roadmap"
          >
            Roadmap
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : styles.inactive
            }
            to="/team"
          >
            Team
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : styles.inactive
            }
            to="/wallet"
          >
            Wallet
          </NavLink>
          <button
            className={styles.button}
            onClick={() => {
              toggle();
              mobileMenuHandler();
            }}
          >
            {/* <img src={wallet} alt="wallet" /> */}

            <span>
              {account
                ? `${account.substring(0, 6)}...${account.substring(
                    account.length - 4
                  )}`
                : "Connect Wallet"}
            </span>
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default MobileMenu;
